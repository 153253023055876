import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contactData } from "../content_data";
import { logoColored } from "../images/assets";

function Footer() {
    return (
        <footer>
            <div className="footer__content">
                <div className='footer__info'>
                    <img src={logoColored} alt='logo' width='160'/>
                    <ul className="footer__links">
                        <h3>Navigate</h3>
                        <li><a href="/#">Home</a></li>
                        <li><a href="/#projects">Projects</a></li>
                        <li><a href="/#vm" >Vision & Mission</a></li>
                        <li><a href="/#team">The Team</a></li>
                    </ul>
                    <div className='profile__contact'>
                    {
                        contactData.map((contact, index) =>
                        <a href={contact.link} key={index} target='_blank' rel='noreferrer'>
                            <FontAwesomeIcon icon={contact.icon}/>
                        </a>)
                    }
                    </div>
                </div>
                <p className='footer_copyright'>Copyright © 2023 - {new Date().getFullYear()} | Domi Technologies. All Rights Reserved</p>
            </div>
        </footer>
    );
}

export default Footer;
