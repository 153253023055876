import React from 'react';
import { useInView } from 'react-intersection-observer';


const SquareCard = ({cardImage, title, text}) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
    });

    return (
        <div className='sqCard'>
          <div ref={ref} className={`sqCard-image ${inView ? 'animate': ''}`}>
            <img src={cardImage} alt='card' />
          </div>
          <div className='sqCard-text'>
            <h2>{title}</h2>
            <div>
              {text}
            </div>
          </div>
        </div>
      );
}

export default SquareCard;